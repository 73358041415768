import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import * as d3 from "d3";

export default class extends Controller {
  static targets = ["container"];
  padding = { top: 20, right: 40, bottom: 0, left: 0 };
  width = 500 - this.padding.left - this.padding.right;
  height = 500 - this.padding.top - this.padding.bottom;
  radius = Math.min(this.width, this.height) / 2;
  postHeaders = {
    "Content-Type": "application/json",
    "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
  };

  connect() {
    this.eventId = this.element.getAttribute("data-event-id");
    this.fetchPrizes(this.eventId)
      .then((data) => {
        if (data) {
          this.updatePrizeData(data);
          if (this.remainingSpins > 0) {
            this.drawWheel();
          }
          this.showReceivedPrizes();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchPrizes(eventId) {
    const url = `/events/${eventId}/product_prizes`;
    return axios
      .get(url, { headers: { Accept: "application/json" }})
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        return null;
      });
  }

  updatePrizeData(data) {
    this.prizes = data.prizes;
    this.receivedPrizes = data.received_prizes;
    this.remainingSpins = data.remaining_spins;
  }

  showReceivedPrizes() {
    const wrapper = document.getElementById("received-prizes");
    wrapper.innerHTML = `
    <div class="p-3 bg-light border rounded">
      <h5 class="text-primary mb-3">Thông Tin Quay Thưởng</h5>
      <hr />
      <p class="mb-3">
        <strong>Số lượt quay còn lại:</strong> 
        <span class="text-success">${this.remainingSpins}</span>
      </p>
      <hr />
      <h6 class="mb-3 text-secondary">Các vật phẩm đã nhận:</h6>
      <ul class="list-group">
        ${this.receivedPrizes
          .map(
            (prize) =>
              `<li class="list-group-item d-flex justify-content-between align-items-center">
                ${prize.product_name}
                <span class="badge bg-primary rounded-pill">${prize.total_quantity}</span>
              </li>`
          )
          .join("")}
      </ul>
    </div>
  `;
  }

  drawWheel() {
    d3.select("#wheel").selectAll("svg").remove();

    const color = d3
      .scaleOrdinal()
      .domain(this.prizes.map((d) => d.product.name))
      .range(d3.schemeCategory10);

    const svg = d3
      .select("#wheel")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`);

    this.wheel = svg.append("g");

    const pie = d3.pie().value((d) => 1);
    const arc = d3.arc().innerRadius(0).outerRadius(this.radius);

    this.wheel
      .selectAll("path")
      .data(pie(this.prizes))
      .join("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.product.name))
      .attr("stroke", "white")
      .style("stroke-width", "2px");

    this.wheel
      .selectAll("text")
      .data(pie(this.prizes))
      .join("text")
      .attr("transform", (d) => {
        const [x, y] = arc.centroid(d);
        const angle = ((d.startAngle + d.endAngle) / 2) * (180 / Math.PI);
        return `translate(${x}, ${y}) rotate(${angle - 90})`;
      })
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text((d) => d.data.product.name)
      .style("font-size", "12px")
      .style("fill", "white");

    svg
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 30)
      .attr("fill", "#333")
      .style("cursor", "pointer")
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .on("click", () => this.spin());

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 5)
      .attr("text-anchor", "middle")
      .text("Quay")
      .style("fill", "white")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .style("pointer-events", "none");

    svg
      .append("path")
      .attr("d", "M 210 0 L 230 -5 L 230 5 Z")
      .attr("fill", "red")
      .attr("stroke", "black")
      .attr("stroke-width", "1px");
  }

  spin() {
    const url = "/spin_attempts/spin";
    axios
      .post(
        url,
        { event_id: this.eventId },
        { headers: this.postHeaders }
      )
      .then((response) => {
        const targetId = response.data.product_prize_id;
        const targetData = this.prizes.find((d) => d.id === targetId);
  
        if (!targetData) {
          console.error("Prize not found for target ID:", targetId);
          return;
        }
  
        const pie = d3.pie().value(() => 1);
        const arcs = pie(this.prizes);
        const targetArc = arcs.find((d) => d.data.id === targetId);
  
        const startAngle = targetArc.startAngle * (180 / Math.PI);
        const endAngle = targetArc.endAngle * (180 / Math.PI);
        const targetAngle = 
          startAngle + 10 + Math.random() * (endAngle - 10 - startAngle);
        
        let finalRotation = targetAngle;
        finalRotation = finalRotation < 90 ? 90 - finalRotation : 450 - finalRotation;
        finalRotation = 360 * 3 + finalRotation;

        const spinDuration = 5000;

        this.wheel
          .transition()
          .ease(d3.easeCubicOut)
          .duration(spinDuration)
          .attrTween("transform", () => {
            return (t) => {
              // Interpolate from currentRotation to finalRotation
              const angle = d3.interpolate(0, finalRotation)(t);
              return `rotate(${angle})`;
            };
          })
          .on("end", () => {
            console.log("Spin animation completed!");
          });
  
        // Fetch prizes after animation setup to ensure execution
        setTimeout(() => {
          this.fetchPrizes(this.eventId)
            .then((data) => {
              if (data) {
                this.updatePrizeData(data);
                this.showReceivedPrizes();
              }
            })
            .catch((error) => {
              console.error("Failed to fetch prizes:", error);
            });
        }, spinDuration); // Ensure this runs after spin animation duration
      })
      .catch((error) => {
        console.error("Spin request failed:", error);
      });
  }
}
