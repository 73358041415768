// app/javascript/controllers/resource_filter_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "dropdown"];
  static values = { resources: Array };

  filter(event) {
    const query = event.target.value.toLowerCase();
    const filtered = this.resourcesValue.filter(resource =>
      resource.name.toLowerCase().includes(query)
    );

    this.renderResults(filtered);
  }

  renderResults(resources) {
    this.dropdownTarget.innerHTML = resources
      .slice(0, 10)
      .map(
        resource =>
          `<div
        class="dropdown-item py-2 hover:bg-gray-25 focus:bg-gray-25 focus:outline-none cursor-pointer"
        data-id="${resource.id}"
        data-action="click->resource-filter#select"
      >
        ${resource.name}
      </div>`

      )
      .join("");
  }

  select(event) {
    const id = event.target.dataset.id;
    const name = event.target.textContent;

    // Update the input and hidden field
    this.inputTarget.value = name;
    this.inputTarget.closest("div").querySelector('input[type="hidden"]').value = id;

    // Clear the dropdown
    this.dropdownTarget.innerHTML = "";
  }
}
