import { Controller } from "@hotwired/stimulus";

export default class ParticipationFrameController extends Controller {
    // Static property to hold the shared frame image
    static sharedFrameImage = null;

    connect() {
        console.log("ParticipationFrameController connected!");
        this.underlayImage = new Image();
        this.initializeCanvas();

        this.canvas.addEventListener('mousedown', this.startDragging.bind(this));
        this.canvas.addEventListener('mousemove', this.dragElement.bind(this));
        this.canvas.addEventListener('mouseup', this.stopDragging.bind(this));
        this.canvas.addEventListener("mousedown", this.detectClickArea.bind(this));

    }

    initializeCanvas() {
        // Rebuild the canvas if it's hidden or needs to be reinitialized
        const canvasContainer = this.element.querySelector('.canvas-container');
        if (canvasContainer) {
            canvasContainer.innerHTML = `<canvas id="frame-canvas-${this.element.dataset.participantId}" class="frame-canvas"></canvas>`;
        }
        const canvasId = `frame-canvas-${this.element.dataset.participantId}`;
        this.canvas = document.getElementById(canvasId);
        this.ctx = this.canvas.getContext('2d');
        this.underlayPosition = { x: this.canvas.width - 150, y: this.canvas.height - 150, width: 100, height: 100 };

        requestAnimationFrame(() => {
            this.loadImageFrame();
        });
    }

    loadImageFrame() {

        if (ParticipationFrameController.sharedFrameImage) {
            this.imgElement = ParticipationFrameController.sharedFrameImage;
            this.updateCanvas();
        } else {
            // Load the frame image and store it in the shared property
            const frameImageUrl = this.element.dataset.frameImageUrl;
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = frameImageUrl;
            console.log("Loading shared image for the first time...");
            img.onload = () => {
                console.log("Frame Image Loaded!");
                ParticipationFrameController.sharedFrameImage = img;
                this.imgElement = img;
                this.updateCanvas();
            };

            img.onerror = (error) => {
                console.error("Error loading image:", error);
            };
        }
    }

    updateCanvasDimension = () => {
        const canvas = this.canvas;
        const containerWidth = canvas.parentElement ? canvas.parentElement.clientWidth : canvas.width; // Fallback if parentElement is undefined
        const scaleRatio = containerWidth / this.imgElement.naturalWidth; // Dynamically calculate scale based on natural width of the image
        canvas.width = containerWidth;
        canvas.height = containerWidth * (this.imgElement.naturalHeight / this.imgElement.naturalWidth); // Maintain aspect ratio
        this.scaleFactor = scaleRatio;
    };

    isPointInUnderlayImage(x, y) {
        const { x: posX, y: posY, width, height } = this.underlayPosition;
        const scaledX = posX * this.scaleFactor;
        const scaledY = posY * this.scaleFactor;
        const scaledWidth = width * this.scaleFactor;
        const scaledHeight = height * this.scaleFactor;

        return x >= scaledX && x <= scaledX + scaledWidth && y >= scaledY && y <= scaledY + scaledHeight;
    }

     isPointOnRectangleBorder(x, y, tolerance = 10) {
        if (!this.drawRectangle || !this.rectangleArea) return false;
        const { x: rectX, y: rectY, width: rectWidth, height: rectHeight } = this.rectangleArea;

        const rectRight = rectX + rectWidth;
        const rectBottom = rectY + rectHeight;
      
        // Check horizontal borders
        if ((y >= rectY - tolerance && y <= rectY + tolerance) || 
            (y >= rectBottom - tolerance && y <= rectBottom + tolerance)) {
          return x >= rectX - tolerance && x <= rectRight + tolerance;
        }
      
        // Check vertical borders
        if ((x >= rectX - tolerance && x <= rectX + tolerance) || 
            (x >= rectRight - tolerance && x <= rectRight + tolerance)) {
          return y >= rectY - tolerance && y <= rectBottom + tolerance;
        }
      
        return false;
      }

    isPointOutsideRectangle(x, y) {
        if (!this.drawRectangle || !this.rectangleArea) return true; // If no rectangle exists, point is considered outside

        const { x: rectX, y: rectY, width: rectWidth, height: rectHeight } = this.rectangleArea;

        // Check if the point is outside the rectangle's boundaries
        const isOutside =
            x < rectX ||                // Left of the rectangle
            x > rectX + rectWidth ||    // Right of the rectangle
            y < rectY ||                // Above the rectangle
            y > rectY + rectHeight;     // Below the rectangle

        return isOutside;
    }

    detectClickArea(event) {
        if (!this.drawRectangle || !this.rectangleArea) {
            console.log("No rectangle to detect clicks.");
            return;
        }

        const rect = this.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (this.isPointOnRectangleBorder(x, y)) {
            console.log("Click detected on the rectangle border.");
        } else if (this.isPointOutsideRectangle(x, y)) {
            this.drawRectangle = false;
            this.rectangleArea = null;
            this.updateCanvas();
        } else {
            console.log("inside image");
        }
    }


    updateCanvas = () => {
        if (!this.imgElement || this.imgElement.naturalWidth === 0 || this.imgElement.naturalHeight === 0) {
            console.warn("Frame image is not loaded yet.");
            return;
        }

        this.updateCanvasDimension();
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const newWidth = this.canvas.width;
        const newHeight = this.canvas.height;
        // Draw underlay image if available
        if (this.underlayImage.src) {
            const underlayX = this.underlayPosition.x * this.scaleFactor;
            const underlayY = this.underlayPosition.y * this.scaleFactor;
            const underlayWidth = this.underlayPosition.width * this.scaleFactor;
            const underlayHeight = this.underlayPosition.height * this.scaleFactor;
            console.log(this.underlayPosition);
            this.ctx.drawImage(this.underlayImage, underlayX, underlayY, underlayWidth, underlayHeight);
        }

        if (this.drawRectangle && this.rectangleArea) {
            const { x, y, width, height } = this.rectangleArea;
            this.ctx.strokeStyle = "red";
            this.ctx.lineWidth = 5;
            this.ctx.strokeRect(x, y, width, height);
            console.log(this.rectangleArea)
        }

        // Draw the frame image
        this.ctx.drawImage(this.imgElement, 0, 0, newWidth, newHeight); // Draw image with new dimensions

        this.loadTextConfig();
    };

    handleUnderlayUpload(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.underlayImage.src = e.target.result;
                this.underlayImage.onload = () => {
                    const underlayAspectRatio = this.underlayImage.width / this.underlayImage.height; // Calculate aspect ratio
                    this.underlayPosition.width = 300; // Set a base width or adjust as needed
                    this.underlayPosition.height = this.underlayPosition.width / underlayAspectRatio; // Maintain aspect ratio
                    this.updateCanvas(); // Update canvas after setting dimensions
                };
            };
            reader.readAsDataURL(file);
        }
    }
    startDragging = (event) => {
        const rect = this.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Ensure isDraggingUnderlay is initialized
        if (typeof this.isDraggingUnderlay === 'undefined') {
            this.isDraggingUnderlay = false;
        }

        const scaledX = this.underlayPosition.x * this.scaleFactor;
        const scaledY = this.underlayPosition.y * this.scaleFactor;
        const scaledWidth = this.underlayPosition.width * this.scaleFactor;
        const scaledHeight = this.underlayPosition.height * this.scaleFactor;

        if (this.isPointOnRectangleBorder(scaledX, scaledY)) {
            this.isScalingUnderlay = true;
            this.dragOffsetX = x;
            this.dragOffsetY = y;
            console.log("Started resizing underlay image.");
        }
        if (this.isPointInUnderlayImage(x, y)) {
            this.isDraggingUnderlay = true;
            this.dragOffsetX = x - scaledX;
            this.dragOffsetY = y - scaledY;
            this.drawRectangle = true;
            const padding = 10; // Optional padding around the underlay
            this.rectangleArea = {
                x: scaledX - padding,
                y: scaledY - padding,
                width: scaledWidth + 2 * padding,
                height: scaledHeight + 2 * padding,
            };
            this.updateCanvas();
        } else {
            console.log("Mouse down event occurred outside of the underlay image area.");

        }
    }

    dragElement = (event) => {
        const rect = this.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (this.isScalingUnderlay) {
            console.log("Mouse Position:", { x, y });
            console.log("Drag Offsets:", { dragOffsetX: this.dragOffsetX, dragOffsetY: this.dragOffsetY });

            const centerX = this.rectangleArea.x + this.rectangleArea.width / 2;
            const centerY = this.rectangleArea.y + this.rectangleArea.height / 2;
            const isMovingInward =
                Math.abs(x - centerX) < Math.abs(this.dragOffsetX - centerX) &&
                Math.abs(y - centerY) < Math.abs(this.dragOffsetY - centerY);

            console.log("Mouse Moving Inward:", isMovingInward);

            const sensitivityFactor = 7000; // Higher values reduce sensitivity
            // Determine scale direction: positive values enlarge, negative values shrink
            const scaleChange =
                (isMovingInward ? -1 : 1) *
                Math.abs(Math.max(x - this.dragOffsetX, y - this.dragOffsetY)) /
                sensitivityFactor;
            const newWidth = this.underlayPosition.width + scaleChange * this.underlayPosition.width;
            const newHeight = newWidth / (this.underlayImage.width / this.underlayImage.height);

            // Ensure the underlay size stays within reasonable bounds
            const minWidth = 50; // Minimum width of the underlay
            const maxWidth = this.canvas.width; // Maximum width based on canvas size

            if (newWidth >= minWidth && newWidth <= maxWidth) {
                this.underlayPosition.width = newWidth;
                this.underlayPosition.height = newHeight;
                this.rectangleArea.width = newWidth * this.scaleFactor + 20;
                this.rectangleArea.height = newHeight * this.scaleFactor + 20;
            }

            this.updateCanvas();
        }

        if (this.isDraggingUnderlay) {
            const previousPosition = { ...this.underlayPosition };

            this.rectangleArea.x = (x - this.dragOffsetX - 10);
            this.rectangleArea.y = (y - this.dragOffsetY - 10);

            this.underlayPosition.x = ((x - this.dragOffsetX) / this.scaleFactor).toFixed(2);
            this.underlayPosition.y = ((y - this.dragOffsetY) / this.scaleFactor).toFixed(2);
            console.log("Underlay position updated from:", previousPosition, "to:", this.underlayPosition);

            this.updateCanvas();
        } 
    }

    stopDragging = () => {
        this.isScalingUnderlay = false;
        this.isDraggingUnderlay = false;
    }

    loadTextConfig = () => {
        const participantDataString = this.element.dataset.participantData;
        let participantData = {};
        if (participantDataString && participantDataString.trim() !== "") {
            try {
                participantData = JSON.parse(participantDataString);
            } catch (error) {
                console.error("Error parsing participant data:", error);
                return;
            }
        } else {
            console.warn("Participant data is empty or undefined.");
            return;
        }

        // Read and parse fields configuration from dataset
        const fieldsConfiguration = this.element.dataset.textField;

        const bib = this.element.dataset.bib;
        const eventName = this.element.dataset.eventName;
        const subEventName = this.element.dataset.subEventName;

        let parsedFields = [];

        if (fieldsConfiguration && fieldsConfiguration.trim() !== "") {
            try {
                console.log("Attempting to replace &quot; with \"."); // Log that we're about to replace.
                const correctedJson = fieldsConfiguration.replace(/&quot;/g, '"');

                parsedFields = JSON.parse(correctedJson);
            } catch (error) {
                console.error("Error parsing fields configuration:", error); // Log if parsing fails.
            }
        } else {
            console.warn("Fields configuration is empty or undefined."); // Log if fields configuration is empty or not provided.
        }

        // Now add text elements on top of the image
        parsedFields.forEach((field) => {
            let displayText;

            // Check if the field text matches any special property
            if (field.text === 'bib') {
                displayText = bib;
            } else if (field.text === 'eventName') {
                displayText = eventName;
            } else if (field.text === 'subEventName') {
                displayText = subEventName;
            } else {
                // Otherwise, get value from participant data
                displayText = participantData[field.text];
            }

            // Fallback to showing the key name if no value is found
            if (displayText === undefined || displayText === null) {
                displayText = field.text;
            }
            const scaledRatio = this.canvas.width / ParticipationFrameController.sharedFrameImage.width;
            // Calculate scaled positions
            const scaledX = (field.x * scaledRatio || 10);
            const scaledY = (field.y * scaledRatio || 10);
            const baseFontSize = field.font_size * scaledRatio || 20;
            const scaledFontSize = Math.max(baseFontSize, 12); // Use square root scaling for smoother growth and cap to a minimum of 12

            // Draw each text configuration on the canvas
            this.ctx.fillStyle = field.color || '#000000';
            this.ctx.font = `${scaledFontSize * scaledRatio}px Arial`;
            this.ctx.fillText(displayText, scaledX, scaledY);

        });
    }

    download(event) {
        console.log("Download button clicked!");

        const participantDataString = this.element.dataset.participantData;
        let participantData = {};
        if (participantDataString && participantDataString.trim() !== "") {
            try {
                participantData = JSON.parse(participantDataString);
            } catch (error) {
                console.error("Error parsing participant data:", error);
                return;
            }
        } else {
            console.warn("Participant data is empty or undefined.");
            return;
        }

        // Extract participant data from dataset
        const firstName = participantData.firstName || "participant";
        const lastName = participantData.lastName || "";
        const eventName = this.element.dataset.eventName || "event";

        // Format filename using first name, last name, and event name
        const sanitizedFirstName = firstName.replace(/[^a-zA-Z0-9]/g, '_');
        const sanitizedLastName = lastName.replace(/[^a-zA-Z0-9]/g, '_');
        const sanitizedEventName = eventName.replace(/[^a-zA-Z0-9]/g, '_');
        const filename = `${sanitizedFirstName}-${sanitizedLastName}-${sanitizedEventName}.png`;

        // Generate a downloadable link for the canvas
        const link = document.createElement('a');
        link.download = filename;
        link.href = this.canvas.toDataURL({
            format: 'png',
            quality: 0.8
        });
        link.click();
    }
}
