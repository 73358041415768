import { Controller } from "@hotwired/stimulus";
import axios from "axios";

// Connects to data-controller="users"
export default class extends Controller {
  static targets = [
    "birthday",
    "email",
    "firstName",
    "middleName",
    "lastName",
    "gender",
    "idCard",
    "phone",
    "province",
    "district",
    "country",
    "address",
    "priceWrapper",
    "bibText",
    "select",
    "sendRequestContainer",
    "sendRequestButton",
    "userIdInput",
  ];

  connect() {
    this.setupModalListeners();
  }

  countryOnChange(event, provinceId) {
    const countryId = event.target.value;
    const provinceSelect = this.provinceTargets.find(
      (target) =>
        target.dataset.participantId == event.target.dataset.participantId
    );

    provinceSelect.innerHTML = "";

    const defaultOption = document.createElement("option");
    defaultOption.text = "Chọn...";
    defaultOption.value = "";
    provinceSelect.add(defaultOption);

    axios
      .get(`/provinces?json=true&country_id=${countryId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const provinces = response.data;

        provinces.forEach((province) => {
          const option = document.createElement("option");
          option.text = province.name;
          option.value = province.id;
          option.selected = provinceId == province.id;
          provinceSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  provinceOnChange(event, districtId) {
    const provinceId = event.target.value;
    const districtSelect = this.districtTargets.find(
      (target) =>
        target.dataset.participantId == event.target.dataset.participantId
    );

    districtSelect.innerHTML = "";

    const defaultOption = document.createElement("option");
    defaultOption.text = "Chọn...";
    defaultOption.value = "";
    districtSelect.add(defaultOption);

    axios
      .get(`/districts?json=true&province_id=${provinceId}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const districts = response.data;

        districts.forEach((district) => {
          const option = document.createElement("option");
          option.text = district.name;
          option.value = district.id;
          option.selected = districtId == district.id;
          districtSelect.add(option);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateParticipant(event) {
    const participantId = event.currentTarget.dataset.participantId;
    const formElement = document.getElementById(`participant-${participantId}`);
    const form = new FormData(formElement);

    const validateInput = (input, pattern, errorMessage) => {
      const existingError = input.parentElement.querySelector('.error-message');
      if (existingError) {
        existingError.remove();
      }

      if (!pattern.test(input.value)) {
        input.scrollIntoView({ behavior: 'smooth' });
        const error = document.createElement('div');
        error.classList.add('error-message');
        error.textContent = errorMessage;
        error.style.color = 'red';
        input.parentElement.appendChild(error);
        return false;
      }
      return true;
    };

    const idOrPassportPattern = /^(?:\d{12}|[A-Z0-9]{6,9})$/; // 12 digits for ID or 6-9 alphanumeric for passport
    const isValidInput =
      validateInput(this.phoneTarget, /^\d{10,11}$/, "Vui lòng nhập số điện thoại hợp lệ (10 đến 11 chữ số).") &&
      validateInput(this.idCardTarget, idOrPassportPattern, "Vui lòng nhập số CCCD hợp lệ (12 chữ số) hoặc số hộ chiếu hợp lệ (6-9 ký tự).") &&
      this.validateIdCard(this.idCardTarget, "Số CCCD hoặc hộ chiếu này đã tồn tại trong bản đăng ký. Vui lòng nhập số khác.", participantId);

    if (!isValidInput) {
      event.preventDefault();
      return;
    }
  }

  showToast(title, message) {
    const toast = document.getElementById("toast-update-participant");
    const toastMessage = document.getElementById("toast-message");
    const toastTitle = document.getElementById("toast-title");

    toastTitle.innerHTML = title;
    toastMessage.innerHTML = `<div>${message}</div>`;
    toast.classList.add("show");
    toast.classList.remove("hide");

    setTimeout(() => {
      if (!toast.classList.contains("hide")) {
        toast.classList.add("hide");
        toast.classList.remove("show");
      }
    }, 5000);
  }

  updateAnswer(event) {
    event.preventDefault();

    const form = new FormData(event.target);
    // TODO: need to show toast
    axios({
      method: "patch",
      url: "/event_registration_answers/batch_upsert",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
      },
    })
      .then((response) => {
        this.showToast("Cập nhật câu trả lời", "Cập nhật thành công.");
      })
      .catch((error) => {
        this.showToast(
          "Cập nhật câu trả lời",
          "Có lỗi xảy ra, vui lòng thử lại sau."
        );
      });
  }

  generateBib(event) {
    const participantId = event.target.dataset.participantId;
    const createBibPath = `/participants/${participantId}/bibs`;
    const token = document.getElementsByName("csrf-token")[0].content;
    this.headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    };
    const loadingIndicator = this.element.querySelector(
      `.loading-indicator[data-participant-id="${participantId}"]`
    );

    event.target.style.display = "none";
    loadingIndicator.style.display = "block";

    axios
      .post(createBibPath, {}, { headers: this.headers })
      .then((response) => {
        loadingIndicator.style.display = "none";
        // Extract data from the response
    const data = response.data;

    // Update the DOM with the new bib number
    const bibDisplay = document.getElementById(`bibs-result-container-${participantId}`);

    bibDisplay.innerHTML = `
      <p class="bib-number">Số BIB: ${data.bib_number}</p>
      <button
        type="button"
        class="btn btn-success"
        data-action="click->participants#select"
        data-bib-id="${data.bib_id}"
        data-bib-number="${data.bib_number}"
        data-participant-id="${participantId}">
        Chọn
      </button>
      <button
        type="button"
        class="btn btn-primary"
        data-action="click->participants#generateBib"
        data-bib-id="${data.bib_id}"
        data-participant-id="${participantId}">
        Quay tiếp
      </button>
    `;
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          alert("Bạn gửi quá nhiều yêu cầu, xin hãy thử lại sau (429)");
        } else {
          console.error("Lỗi tạo bib:", error);
        }
        event.target.style.display = "block";
        loadingIndicator.style.display = "none";
      });
  }

  async select(event) {
    const bibId = event.target.dataset.bibId;
    const participantId = event.target.dataset.participantId;
    const bibNumber = event.target.dataset.bibNumber;
    await this.updateBib(bibId, participantId, bibNumber, "select_bib");
  }

  async discard(event) {
    const bibId = event.target.dataset.bibId;
    const participantId = event.target.dataset.participantId;
    await this.updateBib(bibId, participantId, "", "discard_bib");
  }

  async updateBib(bibId, participantId, bibNumber, action) {
    try {
      const token = document.getElementsByName("csrf-token")[0].content;
      const url = `/participants/${participantId}/bibs/${bibId}/${action}`;

      const response = await axios.patch(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        }
      );

      const bibResultContainer = document.getElementById(`bibs-gen-container-${participantId}`);

      if (action === "select_bib") {
        const bibNumberDisplay = document.getElementById(`bib-number-display-${participantId}`);
        const selectedBibNumber = bibNumber;
        if (bibNumberDisplay) {
          bibNumberDisplay.innerHTML = `${selectedBibNumber}`;
        }

        bibResultContainer.innerHTML = `
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-toggle="modal"
            data-bs-target="#bibTransferModal">
            Chuyển nhượng BIB
          </button>
        `;
      } else if (action === "discard_bib") {
        bibResultContainer.innerHTML = `
          <button
            type="button"
            class="btn btn-secondary"
            data-action="click->participants#generateBib"
            data-participant-id="${participantId}">
            Quay số BIB
          </button>
          <div
            class="loading-indicator"
            data-participant-id="${participantId}"
            style="display: none;">
              <p>Loading...</p>
          </div>
          <div id="bibs-result-container-${participantId}"></div>
        `;
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        alert("Error updating BIB: " + error.response.data.message || "Unknown error.");
      } else {
        console.error("Error:", error.message);
        alert("Something went wrong!");
      }
    }
  }

  triggerFileInput(event) {
    const name = event.target.name;
    const inputSuffix = name.split("file_display_question_")[1];
    const input = document.getElementById(`question_${inputSuffix}`);

    input.click();
  }

  fileInputChanged(event) {
    if (event.target.files.length > 0) {
      const id = event.target.id;
      const inputSuffix = id.split("question_")[1];
      const input = document.querySelector(
        `input[name="file_display_question_${inputSuffix}"]`
      );

      input.value = event.target.files[0].name;

      const hiddenInput = document.querySelector(
        `input[name="question[${inputSuffix}][existing_file]"`
      );

      hiddenInput.value = null;
    }
  }
  setupModalListeners() {
    const modals = this.element.querySelectorAll('.modal');
    modals.forEach(modal => {
      modal.addEventListener('show.bs.modal', (event) => {
        const modalBody = modal.querySelector('.modal-body');
        const container = modalBody.querySelector('[id^="event-frame-container"]');
  
        if (container && !container.dataset.loaded) {
          const url = container.dataset.url;
          console.log("Loading URL:", url); // Log to check what URL is being used
  
          // Fetch and load the partial content into the container
          fetch(url)
            .then(response => {
              if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
              }
              return response.text();
            })
            .then(html => {
              container.innerHTML = html;
              container.dataset.loaded = "true"; // Mark as loaded to prevent reloading
            })
            .catch(error => {
              console.error("Error loading event frame:", error);
              container.innerHTML = "<p>Error loading content. Please try again later.</p>";
            });
        }
      });
    });
  }
  
  subEventOnChange(event) {
    const participantId = event.target.dataset.participantId;
    const newSubEventId = event.target.value;

    const url = `/calculate_price_change?participant_id=${participantId}&sub_event_id=${newSubEventId}`;

    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (!response.data) {
          return;
        }

        const data = response.data;

        if (data.error) {
          // toast error
          alert(data.error);
          return;
        }

        document.getElementById("amount").value = data.price;
        if (data.price) {
          this.priceWrapperTarget.innerHTML = `Giá: ${data.price}`;
        } else {
          this.priceWrapperTarget.innerHTML = "Miễn phí";
        }
      });
  }

  searchBib() {
    const bib = document.getElementById("bib").value;
    const param = bib.length ? `&bib=${bib}` : "";
    window.location.href = `${window.location.href.split("&bib")[0]}${param}`;
  }

  searchUsers(event) {
    const [data, status, xhr] = event.detail;
  
    if (xhr.status === 200) {
      // Update the search results div with the partial
      document.getElementById("searchResults").innerHTML = xhr.response;
    }
  }

  updateSendRequestButton() {
    const selectedUserId = this.selectTarget.value;

    if (selectedUserId) {
      this.sendRequestContainerTarget.style.display = "block";
      this.sendRequestButtonTarget.setAttribute("data-user-id", selectedUserId);
      this.userIdInputTarget.value = selectedUserId;
    } else {
      this.sendRequestContainerTarget.style.display = "none";
      this.sendRequestButtonTarget.setAttribute("data-user-id", "");
      this.userIdInputTarget.value = "";
    }
  }

  validateIdCard(idCardInput, errorMessage, selectedParticipantId, event) {
    const existingError = idCardInput.parentElement.querySelector('.error-message');
    if (existingError) {
      existingError.remove();
    }

    // Parse the participation data from the hidden field
    const participationData = JSON.parse(document.getElementById("participations-data").value);
    const idCardValue = idCardInput.value.trim();

    // Filter participation data to exclude the selected participant ID
    const filteredParticipationData = participationData.filter(
      participant => participant.id !== parseInt(selectedParticipantId)
    );

    const existingIdCards = filteredParticipationData.map(participant => participant.id_card);

    // Check if the ID card already exists
    if (existingIdCards.includes(idCardValue)) {
      idCardInput.scrollIntoView({ behavior: 'smooth' });

      // Add error message dynamically
      const error = document.createElement('div');
      error.textContent = errorMessage;
      error.className = 'error-message';
      error.style.color = 'red';
      error.style.marginTop = '5px';
      idCardInput.parentElement.appendChild(error);

      return false;
    }

    return true;
  }
}
